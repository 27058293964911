<template>
  <div class="pl-4" style="max-width: 1200px;">
    <v-row class="my-4">
      <v-col cols="12" md="8">
        <div v-if="error">
          <span v-if="error == 1"
            ><h2 class="red--text">
              Wir konnten leider keine passenden Versicherungen finden
            </h2>
            <span v-if="error > 1">
              Bitte schreiben Sie uns eine E-Mail, damit wir unseren Rechner
              prüfen können. Eventuell liegt ein technischer Fehler vor.</span
            >
          </span>

          <span v-if="tarifenot.length > 0">
            Wir haben für Sie folgende Tarife gesprüft. Diese sind aber aufgrund
            Ihrer Berechnungsvorgaben nicht abschließbar.<br /><br />
            <v-row v-for="item in tarifenot" :key="item.tier_id">
              <v-col cols="12" md="12"
                ><b>{{ item.un_name }} {{ item.tier_title }}</b>

                <ul class="my-0">
                  <li v-for="info in item.nogoinfo">{{ info }}</li>
                </ul>
              </v-col>
              <v-col cols="12" md="4"> </v-col>
            </v-row>
          </span>
          <span v-if="error > 1"
            ><h2 class="red--text">Fehler: Nr. {{ error }}</h2></span
          >
        </div>
        <div v-if="tarife">
          <!-- <rechner-vorgabe :berechnung="berechnung" type="ergebnis" /> -->
          <v-row>
            <v-col cols="12" md="12">
              <h1 class="greytext">
                Ihr Ergebnis für:
                <span v-if="berechnung.ber_name != ''"
                  >{{ berechnung.ber_name }} |
                </span>
                <span v-if="berechnung.ber_tierart == 2">Katze</span>
                <span v-if="berechnung.ber_tierart == 1">Hund</span>,
                {{ berechnung.ber_alter }} Jahre
              </h1>
              <div style="border-right:4px solid #d84315;">
                <v-row>
                  <v-col cols="12" md="1" style="max-width:40px;">
                    <v-icon class="green--text">label_important</v-icon></v-col
                  >
                  <v-col cols="12" md="11" xs="12"
                    ><h3>
                      AntragAnnahmecheck |<span style="font-weight:normal">
                        &nbsp;Tier-Krankenversicherung
                      </span>
                    </h3>

                    <v-row class="mt-2">
                      <v-col cols="12" md="2"><b>Positiv:</b></v-col>
                      <v-col cols="12" md="10"
                        ><b class="green--text">{{ tarife.length }} Tarife</b>
                        sind beantragbar |
                        <a @click="showVorgaben = !showVorgaben"
                          >diese Kriterien werden berücksichtigt</a
                        >
                        <rechner-vorgabe
                          v-if="showVorgaben"
                          :berechnung="berechnung"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="my-0" v-if="tarifenot.length > 0">
                      <v-col cols="12" md="2"><b>Negativ:</b></v-col>
                      <v-col cols="12" md="10"
                        >Bei

                        <span v-if="tarifenot.length > 1">
                          <b class="red--text">
                            {{ tarifenot.length }} Tarifen
                          </b>
                        </span>
                        <span v-if="tarifenot.length == 1">
                          <b class="red--text"> diesem Tarif </b>
                        </span>
                        würde Ihr Antrag abgelehnt werden
                        <span v-if="tarifenot.length > 0"
                          ><br />
                          <a @click="showNogo = !showNogo"
                            >Lesen Sie hier wieso manche Tarife nicht
                            abschließbar sind...</a
                          >
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="mt-10">
        Hier finden Sie eine
        <a @click="showNichtVergleicher = !showNichtVergleicher"
          >Übersicht aller {{ showBerechnungTierarzt }}</a
        >
        die wir ausgewertet haben und welche Tarife bereits im Vergleichsrechner
        dargestellt werden.

        <v-dialog v-model="showNogo" v-if="tarifenot.length > 0" width="888">
          <v-card class="pa-4">
            <h2>Folgende Tarife sind nicht abschließbar:</h2>
            <br />
            <v-row v-for="item in tarifenot" :key="item.tier_id">
              <v-col cols="12" md="12"
                ><b>{{ item.un_name }} {{ item.tier_title }}</b>
                <ul>
                  <li v-for="info in item.nogoinfo">{{ info }}</li>
                </ul>
              </v-col>
              <v-col cols="12" md="4"> </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <div class="my-4" style="border-bottom:4px solid #f2f2f2"></div>
    <div v-if="tarife">
      <div v-for="item in tarife" :key="item.tier_id" class="mb-8">
        <tarif-card
          :tarif="item"
          :alletarife="tarife"
          :berechnung="berechnung"
        />
      </div>
    </div>
    <v-dialog v-model="showNichtVergleicher" width="999">
      <index-no-vergleich :tierart="tierart" />
    </v-dialog>
  </div>
</template>

<script>
  import tarifCard from "./tarifCard";
  import RechnerVorgabe from "./RechnerVorgabe";
  import IndexNoVergleich from "./IndexNoVergleich";

  export default {
    name: "index-rechner",
    components: {
      tarifCard,
      RechnerVorgabe,
      IndexNoVergleich,
    },

    data: function() {
      return {
        tierart: "hund",
        showNogo: false,
        showVorgaben: false,
        tarife: false,
        tarifenot: false,
        berechnung: false,
        error: false,
        showBerechnungTierarzt: "Hunde-Krankenversicherungen",
        showVorgabe: false,
        showNichtVergleicher: false,
        formRechner: {
          alter: 4,
          rasse: 1,
          type: "token",
          token: "",
        },
      };
    },
    created() {
      if (this.$route.params.token != "") {
        this.formRechner.token = this.$route.params.token;
        this.getBerechnungErgebnis();
      }
    },

    methods: {
      getBerechnungErgebnis() {
        this.$store.commit("start_loading_full");
        this.$store
          .dispatch("getBerechnungErgebnis", this.formRechner)
          .then((resp) => {
            if (resp.data.error) {
              this.error = resp.data.error;
              this.tarifenot = resp.data.tarifenot;
              this.$store.commit("stop_loading_full");
            }

            if (resp.data.tarife) {
              this.$store.commit("stop_loading_full");
              this.tarife = resp.data.tarife;
              this.tarifenot = resp.data.tarifenot;
              this.berechnung = resp.data.berechnung;
              if (this.berechnung.ber_tierart == 2) {
                this.showBerechnungTierarzt = "Katzen-Krankenversicherung";
                this.tierart = "katze";
              }
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
    },
  };
</script>
