<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" md="12"
        ><h2>Leistungsbeschreibung {{ tarif.tier_title }}</h2></v-col
      >
    </v-row>
    <v-row style="width: 888px; background-color: #f2f2f2">
      <v-col cols="12" md="4" xs="12"><b>Monatlicher Beitrag</b></v-col>
      <v-col cols="12" md="8" xs="12"
        >{{ tarif.preis }} €
        <span v-if="tarif.zuschlag > 0" class="caption"
          ><br />inkl. Risiko-Zuschlag {{ tarif.zuschlag }} €</span
        >
      </v-col>
    </v-row>
    <v-row
      v-for="item in origin"
      :key="item.value"
      class="mb-4"
      style="width: 888px; background-color: #f2f2f2"
    >
      <v-col cols="12" md="12" v-if="!item.showintern && tarif[item.value]">
        <v-row>
          <v-col cols="12" md="4" xs="12">
            <b>{{ item.title }}</b>

            <!-- <br />{{ item.value }} | {{ item.type }}--></v-col
          >
          <v-col cols="12" md="7" xs="12">
            <span class="pb-0 mb-0">
              {{ showFieldComputed(item.value, "hund") }}</span
            >
          </v-col>
          <v-col cols="12" md="1" xs="12">
            <ButtonTip
              v-if="item.popup"
              cssstyle="
                 
                
                  cursor: pointer;
                "
              text="Info"
              icon="mdi-delete"
              :tooltip="item.value"
              eventname="deleterec"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import ButtonTip from "./../base/helper/ButtonTip";
  import fieldMixin from "../mixins/fieldMixin";
  export default {
    name: "index-beschreibung",
    components: { ButtonTip },
    mixins: [fieldMixin],
    props: ["tarif"],
    data: function() {
      return {
        origin: false,
        table: "hund",
      };
    },
    created() {
      this.origin = this.hund;
    },

    methods: {},
  };
</script>
