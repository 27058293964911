<template>
  <div class="pa-4">
    <h2 v-if="berechnung.ber_tierart <= 1">
      Vergleichen Sie bis zu 3 Hunde-Krankenversicherungen miteinander
    </h2>
    <h2 v-if="berechnung.ber_tierart == 2">
      Vergleichen Sie bis zu 3 Katzen-Krankenversicherungen miteinander
    </h2>

    <p>Wählen Sie jetzt noch 2 Tarife zum Vergleich aus.</p>
    <v-row>
      <v-col cols="12" md="3"> </v-col>
      <v-col cols="12" md="3"
        >Tarif 1: <br />

        <a style="font-size: 14px">{{ tarif1.tier_title }}</a></v-col
      >
      <v-col cols="12" md="3"
        >Tarif 2
        <select
          class="blue--text"
          style="padding-left: 4px; font-size: 14px;width:230px;"
          @change="pushTarifToVergleich($event, 2)"
        >
          <option value selected>Tarif 2 wählen</option>
          <option
            style="padding-left: 10px"
            v-for="item in alletarife"
            :value="item.tier_id"
            :key="item.tier_id"
          >
            {{ item.tier_title }}
          </option>
        </select>
      </v-col>

      <v-col cols="12" md="3"
        >Tarif 3
        <select
          class="blue--text"
          style="padding-left: 4px; font-size: 14px;width:230px;"
          @change="pushTarifToVergleich($event, 3)"
        >
          <option value selected>Tarif 3 wählen</option>
          <option
            style="padding-left: 10px"
            v-for="item in alletarife"
            :value="item.tier_id"
            :key="item.tier_id"
          >
            {{ item.tier_title }}
          </option>
        </select>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="12"> Tierwert {{ tarif2.tier_wert }} </v-col>
    </v-row> -->
    <v-row class="mb-4" style="background-color: #f2f2f2">
      <v-col cols="12" md="3" xs="12"><b>Monatsbeitrag</b> </v-col>
      <v-col cols="12" md="3" xs="12">
        {{ tarif.preis }} €
        <span v-if="tarif.zuschlag > 0" class="caption"
          ><br />inkl. Risiko-Zuschlag {{ tarif.zuschlag }} €</span
        >
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <span v-if="tarif2.preis">
          {{ tarif2.preis }} €
          <span v-if="tarif2.zuschlag > 0" class="caption"
            ><br />inkl. Risiko-Zuschlag {{ tarif2.zuschlag }} €</span
          >
        </span>
      </v-col>
      <v-col cols="12" md="3" xs="12">
        <span v-if="tarif3.preis">
          {{ tarif3.preis }} €
          <span v-if="tarif3.zuschlag > 0" class="caption"
            ><br />inkl. Risiko-Zuschlag {{ tarif3.zuschlag }} €</span
          >
        </span>
      </v-col>
    </v-row>
    <div v-for="item in origin" :key="item.value">
      <v-row
        v-if="!item.showintern"
        class="mb-4"
        style="background-color: #f2f2f2"
      >
        <v-col cols="12" md="3" xs="12">
          <b>{{ item.title }}</b>
        </v-col>
        <v-col cols="12" md="3" xs="12">
          <span class="pb-0 mb-0">
            {{ showFieldComputedNr(item.value, "hund", "1") }}</span
          >
        </v-col>
        <v-col cols="12" md="3" xs="12">
          <span class="pb-0 mb-0" v-if="tarif2[item.value]">
            {{ showFieldComputedNr(item.value, "hund", "2") }}
          </span>
        </v-col>

        <v-col v-if="tarif3" cols="12" md="3" xs="12">
          <span class="pb-0 mb-0" v-if="tarif3[item.value]">
            {{ showFieldComputedNr(item.value, "hund", "3") }}</span
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import fieldMixin from "../mixins/fieldMixin";
  export default {
    name: "index-beschreibung",
    components: {},
    mixins: [fieldMixin],
    props: ["tarif", "alletarife", "berechnung"],
    data: function() {
      return {
        chosenTarif2: 0,
        chosenTarif3: 0,
        tarif1: false,
        tarif2: false,
        tarif3: false,
        origin: false,
        table: "hund",
      };
    },
    created() {
      this.origin = this.hund;
      this.tarif1 = this.tarif;
    },

    methods: {
      pushTarifToVergleich(event, tarif_nr) {
        this.$log.debug("pushTarifToVergleich", tarif_nr);
        for (var j = 0; j < this.alletarife.length; j++) {
          if (this.alletarife[j].tier_id == event.target.value) {
            if (tarif_nr == 2) {
              this.tarif2 = this.alletarife[j];
            }
            if (tarif_nr == 3) {
              this.tarif3 = this.alletarife[j];
            }
          }
        }
      },
    },
  };
</script>
