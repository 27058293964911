<template>
  <div>
    <v-card class="no-rounded-card mb-6 body-2" outlined :style="stylecard">
      <v-row>
        <v-col cols="12" md="1">
          <v-row>
            <v-col cols="12" md="12">
              <div
                style="
                  padding: 12px;
                  background-color: #404040;
                  min-height: 40px;
                  min-width: 80px;
                  font-size: 24px;
                  color: white;
                "
              >
                #{{ tarif.ranking }}
              </div>

              <div
                class="redground"
                style="
                  padding: 12px;

                  min-height: 40px;
                  min-width: 80px;
                "
              >
                <a
                  style="
                    font-size: 24px;
                    color: white;
                    font-weight: bold;
                    cursor: pointer;
                  "
                  @click="showRechenweg = !showRechenweg"
                  >{{ tarif.tier_wert }} %</a
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="7">
          <v-row>
            <v-col cols="12" md="10" class="pt-6">
              <v-row>
                <v-col cols="12" md="12">
                  <h2>
                    <a @click="showTb = !showTb">{{ tarif.tier_title }}</a>
                    &nbsp;
                  </h2>
                  <!-- ID: {{ tarif.tier_id }} | Risikoklasse {{ tarif.risiko }} ({{
                    tarif.risiko_type
                  }})  -->
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-row>
                    <v-col cols="12" md="3">Durchschnitts-erstattung:</v-col>
                    <v-col cols="12" md="9"
                      ><b
                        ><a @click="showRechenweg = !showRechenweg"
                          >{{ tarif.tier_wert }} %</a
                        ></b
                      >
                      |

                      <b v-if="tarif.empfehlung == 1" class="green--text"
                        >Tipp "Beste Leitung"</b
                      >
                      <b v-if="tarif.empfehlung == 2" class="green--text"
                        >Tipp "Preis-Leistung"</b
                      >
                      <v-dialog
                        v-if="showRechenweg"
                        v-model="showRechenweg"
                        width="888"
                      >
                        <spezial-pop
                          :item="tarif"
                          type="rechenweg"
                        ></spezial-pop>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row v-if="tarif.tier_wartezeit">
                    <v-col cols="12" md="3">Wartezeit</v-col>
                    <v-col cols="12" md="9"
                      ><b>{{ tarif.tier_wartezeit }}</b></v-col
                    >
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="3"
                      >Tierbehandlung<br />
                      Max. Erstattung pro Jahr</v-col
                    >
                    <v-col cols="12" md="9"
                      ><b>{{ tarif.tier_behandlung_erstattung_zahl }} </b>
                      <br />
                      max. Erstattung pro Jahr:
                      <b>{{ tarif.tier_behandlung_erstattung_max_jahr }}</b>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="3">Tieroperation:</v-col>
                    <v-col cols="12" md="9 "
                      ><b>{{ tarif.tier_op_erstattung_zahl }}</b> <br />
                      max. Erstattung pro Jahr:
                      <b>{{ tarif.tier_op_erstattung_max_jahr }}</b></v-col
                    >
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="3">Vorsorge pro Jahr </v-col>
                    <v-col cols="12" md="9"
                      ><b
                        v-if="
                          tarif.tier_beg_vorsorge == 0 &&
                            tarif.tarif_id != 4 &&
                            tarif.tarif_id != 5 &&
                            tarif.tarif_id != 6
                        "
                        class="red--text"
                        >keine Erstattung</b
                      >
                      <b
                        v-if="
                          (tarif.tier_beg_vorsorge == 0 &&
                            tarif.tarif_id == 4) ||
                            tarif.tarif_id == 5 ||
                            tarif.tarif_id == 6
                        "
                        >leistet für Vorsorge im Rahmen der Summenbegrenzungen
                        für Tierbehandlung</b
                      >
                      <b v-if="tarif.tier_beg_vorsorge > 0"
                        >{{ tarif.tier_beg_vorsorge }} €</b
                      >
                      (z.B. Impfungen, etc.)
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="3">
                      Alternative Behandlungs-methoden</v-col
                    >
                    <v-col cols="12" md="9 "
                      >Physiotherapie:
                      <b>{{ tarif.tier_behandlung_physio }}</b> <br />
                      Homöopathie:
                      <b>{{ tarif.tier_behandlung_homopathie }}</b> <br />
                      Akupunktur: <b>{{ tarif.tier_behandlung_akupunktur }}</b>
                    </v-col>
                  </v-row>

                  <!-- 
                  | Leistungspunkte:{{ tarif.punkteleistung }} | PreisPunkte:{{
                    tarif.punktepreis
                  }}
                  | Risikoklasse: {{ tarif.risiko }} (Liste:
                  {{ tarif.risiko_type }} ) | Tarif-ID: {{ tarif.tier_id }}:
                  High | Zuschlag: {{ tarif.zuschlag }} | -->
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="pt-6">
              <span v-if="tarif.tier_preis_est == 0">
                <h2>{{ tarif.preis }} €</h2>
              </span>
              <span v-if="tarif.tier_preis_est == 1">
                ca.
                <h2>{{ tarif.preis }} €</h2>
                <a @click="tarif.showCa = !tarif.showCa">Wieso ca.?</a> <br />
                <v-dialog
                  v-if="tarif.showCa"
                  v-model="tarif.showCa"
                  width="888"
                >
                  <spezial-pop :item="tarif" type="preis_est"></spezial-pop>
                </v-dialog>
              </span>
              monatlich
              <span v-if="tarif.zuschlag > 0"
                >Inkl. <b>{{ tarif.zuschlag }} €</b> Risiko-Zuschlag für
                Jagdnutzung</span
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" class="pa-6">
          <v-card tile elevation="1" color="#f2f2f2">
            <v-app-bar v-if="tarif.empfehlung == 1" color="#d84315">
              <v-toolbar-title
                class="py-0"
                style="color: white; font-weight: bold"
                >Empfehlung beste Leistung</v-toolbar-title
              >
            </v-app-bar>

            <v-app-bar v-if="tarif.empfehlung == 2" color="#d84315">
              <v-toolbar-title style="color: white; font-weight: bold"
                >Alternativ-Empfehlung</v-toolbar-title
              >
            </v-app-bar>

            <v-card-text>
              <ul>
                <li v-for="item in tarif.highlight" :key="item">
                  <span v-html="item"></span>
                </li>
                <li v-if="tarif.un_abschluss_url != ''">
                  <a
                    @click="saveAntragClickend()"
                    un_abschluss_url:href="tarif.un_abschluss_url"
                    target="_blank"
                    >jetzt Versicherungsschutz beantragen
                  </a>
                </li>
              </ul>
            </v-card-text>
          </v-card>
          <v-btn
            class="nocaps mr-4"
            color="#d84315"
            depressed
            tile
            style="font-size: 18px;width:100%"
            v-if="tarif.un_abschluss_url != '' && antragMode == 2"
          >
            <a
              :href="`/abschluss/${tarif.tier_id}/${berechnung.ber_token}`"
              target="_blank"
              class="white--text"
              >Jetzt online beantragen
            </a></v-btn
          >
          <v-btn
            class="nocaps mr-4"
            color="#d84315"
            depressed
            tile
            style="font-size: 18px;width:100%"
            v-if="tarif.un_abschluss_url != '' && antragMode == 1"
          >
            <a
              @click="saveAntragClickend()"
              :href="tarif.un_abschluss_url"
              target="_blank"
              class="white--text"
              >Jetzt online beantragen
            </a>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-4 ml-2">
        <v-col cols="12" md="12">
          <v-btn
            class="nocaps mr-4"
            color="#d84315"
            depressed
            tile
            style="font-size: 18px"
            v-if="tarif.un_abschluss_url != '' && antragMode == 2"
          >
            <a
              :href="`/abschluss/${tarif.tier_id}/${berechnung.ber_token}`"
              target="_blank"
              class="white--text"
              >Jetzt online beantragen
            </a></v-btn
          >
          <v-btn
            class="nocaps mr-4"
            color="#d84315"
            depressed
            tile
            style="font-size: 18px"
            v-if="tarif.un_abschluss_url != '' && antragMode == 1"
          >
            <a
              @click="saveAntragClickend()"
              :href="tarif.un_abschluss_url"
              target="_blank"
              class="white--text"
              >Jetzt online beantragen
            </a>
          </v-btn>
          <v-btn
            class="nocaps mr-4"
            color="#d84315"
            @click="showTb = !showTb"
            outlined
          >
            Leistungen
          </v-btn>
          <v-btn
            class="nocaps"
            color="#d84315"
            outlined
            @click="showDirectVergleich = !showDirectVergleich"
          >
            3 Tarife im Vergleich
          </v-btn>
        </v-col>
      </v-row>
      <v-dialog width="999" v-model="showTb">
        <v-card class="pa-4">
          <index-beschreibung :tarif="tarif" />
        </v-card>
      </v-dialog>

      <v-dialog width="999" v-model="showDirectVergleich">
        <v-card class="pa-4">
          <index-direct-vergleich
            :tarif="tarif"
            :alletarife="alletarife"
            :berechnung="berechnung"
          />
        </v-card>
      </v-dialog>
    </v-card>
    <div style="height: 20px">&nbsp;</div>
  </div>
</template>

<script>
  import ButtonTip from "./../base/helper/ButtonTip";
  import IndexBeschreibung from "./../hund/IndexBeschreibung";
  import IndexDirectVergleich from "./../hund/IndexDirectVergleich";
  import SpezialPop from "./../base/helper/SpezialPop";

  export default {
    props: ["tarif", "alletarife", "berechnung"],
    name: "tarif-card",
    components: {
      ButtonTip,
      IndexBeschreibung,
      IndexDirectVergleich,
      SpezialPop,
    },
    data: function() {
      return {
        antragMode: 1,
        showRechenweg: false,
        showTb: false,
        stylecard: "min-height: 160px;",
        showDirectVergleich: false,

        select: {
          action: "saveclickantrag",
          tier_id: this.tarif.tier_id,
          ber_id: this.berechnung.ber_id,
          name: this.tarif.tier_title,
        },
      };
    },
    created() {
      if (this.tarif.empfehlung > 0) {
        this.stylecard = "min-height: 160px;border-right:4px solid #d84315;";
      }
      this.getAntragMode();
    },
    computed: {
      show_tier_wert() {
        if (this.tarif.tier_id > 0) {
          return this.tarif.tier_wert + "%";
        } else {
          return "";
        }
      },
    },
    methods: {
      getAntragMode() {
        let select = {};
        select.action = "getAntragMode";
        this.$store
          .dispatch("apiTarifPost", select)
          .then((resp) => {
            if (resp.data.antragmode) {
              this.antragMode = resp.data.antragmode;
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      saveAntragClickend() {
        this.$log.debug("saveclickantrag", this.select);
        this.$store
          .dispatch("apiTarifPost", this.select)
          .then(() => {})
          .catch((err) => {
            this.$log.error(err);
          });
      },
    },
  };
</script>
